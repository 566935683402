import * as React from "react"
import Layout from "../components/common/layout"
import {
  Separator,
  BGLinearGradient,
} from "../components/common/styledComponentsHelper"

export const Head = () => (
  <>
    <title>500: Internal Server Error</title>
    <meta
      name="facebook-domain-verification"
      content="xooo742lkxtkqa24ish1wyf2ppfc4e"
    />
  </>
)

const NotFoundPage500 = () => (
  <Layout>
    <BGLinearGradient>
      <div style={{ marginTop: "85px" }}>
        <Separator />
        <div className="container text-center">
          <h1 className="text-light text-uppercase text-bold fw-bold">
            500
            <br /> Error
          </h1>
          <p className="text-light">
            Es tut uns leid. Aber der Server ist auf eine Situation gestoßen,
            von der er nicht weiß, wie er sie bewältigen soll.
          </p>
          <a className="btn btn-success text-dark" href="/#">
            zur Startseite
          </a>
        </div>
      </div>
    </BGLinearGradient>
  </Layout>
)

export default NotFoundPage500
